<template>
  <div>
    <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="d-flex">
            <v-menu  bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small
                color="blue-grey darken-1"
                dark
                v-bind="attrs"
                v-on="on"
              >
              
                {{selectedyear}}
                <v-icon class="ml-1 mt-1">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in yearlist"
                :key="i"
                @click="yearSelect(item)"
              >
                <v-list-item-title >{{item}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn class="shadow-off" small text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
        </div>
      </v-card-text>
      <v-card-text>
        <DataTable
      :headers="headerMonth"
      :items="formatedData"
      :enableslot="['dashboard', 'tds_monthly', 'tds_quarterly','gstr_1','gstr_3b','refund']"
      :loading="loading" >
      <template v-slot:dashboard="{ item }">
          <MonthlySummary :yeardata="yeardata[item.no]" :viewonly="!($nova.hasRight('tds_monthly', ['add', 'edit']) || $nova.hasRight('tds_monthly', ['review']))" :file="yeardata[item.no]?.summary?.['dashboard']?.file" :stage="yeardata[item.no]?.summary?.['dashboard']?.stage" type="dashboard" :item="item" :menudisable="yeardata[item.no]?.stage === undefined" @message-success="getData"/>
      </template>
      <template v-slot:tds_monthly="{ item }">
        <!-- <div v-if="(yeardata[item.no] && yeardata[item.no].stage !== undefined)">
          Upcoming 123
        </div> -->
        <MonthlySummary :yeardata="yeardata[item.no]" :viewonly="!($nova.hasRight('tds_monthly', ['add', 'edit']) || $nova.hasRight('tds_monthly', ['review']))" :file="yeardata[item.no]?.summary?.['tds_monthly']?.file" :stage="yeardata[item.no]?.stage" type="tds_monthly" :item="item" :viewanalysis="true" :stageshow="false" />
        <v-btn text x-small class="shadow-off mt-1" color="blue" @click.stop="$nova.gotoLink({path: `/tds/monthly/${selectedyear}/${item.no}`})">
          Goto Analysis
        </v-btn>
      </template>
      <template v-slot:tds_quarterly="{ item }">
          <MonthlySummary :yeardata="yeardata[item.no]" :viewonly="!($nova.hasRight('tds_monthly', ['add', 'edit']) || $nova.hasRight('tds_monthly', ['review']))" :file="yeardata[item.no]?.summary?.['tds_quarterly']?.file" :stage="yeardata[item.no]?.summary?.['tds_quarterly']?.stage" type="tds_quarterly"  :item="item" :menudisable="yeardata[item.no]?.stage === undefined" @message-success="getData"/>
      </template>
      <template v-slot:gstr_1="{ item }">
           <MonthlySummary :yeardata="yeardata[item.no]" :viewonly="!($nova.hasRight('tds_monthly', ['add', 'edit']) || $nova.hasRight('tds_monthly', ['review']))" :file="yeardata[item.no]?.summary?.['gstr_1']?.file" :stage="yeardata[item.no]?.summary?.['gstr_1']?.stage" type="gstr_1" :item="item" :menudisable="yeardata[item.no]?.stage === undefined" @message-success="getData"/>
      </template>
      <template v-slot:gstr_3b="{ item }">
          <MonthlySummary :yeardata="yeardata[item.no]" :viewonly="!($nova.hasRight('tds_monthly', ['add', 'edit']) || $nova.hasRight('tds_monthly', ['review']))" :file="yeardata[item.no]?.summary?.['gstr_3b']?.file" :stage="yeardata[item.no]?.summary?.['gstr_3b']?.stage" type="gstr_3b" :item="item" :menudisable="yeardata[item.no]?.stage === undefined" @message-success="getData"/>
      </template>
      <template v-slot:refund="{ item }">
        <MonthlySummary :yeardata="yeardata[item.no]" :viewonly="!($nova.hasRight('tds_monthly', ['add', 'edit']) || $nova.hasRight('tds_monthly', ['review']))" :file="yeardata[item.no]?.summary?.['refund']?.file" :stage="yeardata[item.no]?.summary?.['refund']?.stage" type="refund" :item="item" :menudisable="yeardata[item.no]?.stage === undefined" @message-success="getData"/>
          <!-- <div @click.stop>
            <v-menu left offset-x  :nudge-width="80" elevation-0  :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <div  v-bind="attrs" v-on="on">
                      <v-chip v-if="((yeardata[item.no] || {}).stage === 0)" label small color="info">In progress</v-chip>
                      <v-chip v-else-if="((yeardata[item.no] || {}).stage === 1)" label small color="warning">Under review</v-chip>
                      <v-chip v-else-if="((yeardata[item.no] || {}).stage === 2)" label small color="success">Completed</v-chip>
                      <v-chip v-else-if="(((yeardata[item.no] || {}).stage || 0) === 0)" label small color="">Upcoming</v-chip>
                  </div>
                </template>
                <v-card>
                  <v-card-text>
                      <div>
                        <div class="d-flex" >
                            <v-menu left offset-y :nudge-width="40" elevation-0 >
                              <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on">
                                    <v-chip outlined small label>
                                      <span v-if="((yeardata[item.no] || {}).stage === 0)" class="info--text">In progress </span>
                                      <span v-else-if="((yeardata[item.no] || {}).stage === 1)" class="warning--text">Under review</span>
                                      <span v-else-if="((yeardata[item.no] || {}).stage === 2)" class="success--text">Completed</span>
                                      <span v-else-if="(((yeardata[item.no] || {}).stage || 0) === 0)" class="">Upcoming</span>
                                      <v-icon right>mdi-menu-down</v-icon>
                                    </v-chip>
                                  </div>
                              </template>
                              <v-card >
                                  <v-list dense class="pa-0 border-left-default">
                                    <v-list-item @click="SelectStage('inprogress','refund',item)">
                                        <v-list-item-title>
                                          <span label small class="info--text">In progress</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="SelectStage('underreview','refund',item)">
                                        <v-list-item-title>
                                          <span label small class="warning--text">Under review</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="SelectStage('completed','refund',item)">
                                        <v-list-item-title>
                                          <span  label small class="success--text">Completed</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="SelectStage('notdue','refund',item)">
                                        <v-list-item-title>
                                          <span  label small class="error--text">Not due</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                              </v-card>
                            </v-menu>
                            <v-spacer></v-spacer>
                            <div class="ml-2">
                              <lb-file :hidedetails="true" :drag="false"  accept=".csv,.xlsx,.xls" label="" :displayname="false" />
                            </div>
                        </div>
                        <div class="align-center mt-2">
                            <span  @click="$nova.downloadFile()" class="mx-2 cursor-pointer blue--text text--darken-2 caption">
                              <v-icon class="mr-1 blue--text text--darken-2">mdi-file</v-icon>
                              filename
                            </span>
                        </div>
                      </div>
                  </v-card-text>
                </v-card>
            </v-menu>
          </div> -->
      </template>
        </DataTable>
      </v-card-text>

     <!-- <v-card-text> 
        <v-row>
          <template>
            <v-col cols="2" v-for="i in [4,5,6,7,8,9,10,11,12,1,2,3]" :key="i">
              <v-card class="hoverable shadow-off" link color="" :to="$nova.generateLink(`/tds/monthly/${selectedyear}/${i}`)">
                <v-card-text class="border-left-grey border-on py-2 pb-4 ma-0">
                  <div class="subtitle-1">
                    {{new Date(new Date().setMonth(i-1)).toLocaleString('default', { month: 'long' })}} 
                  </div>
                    <div>
                      <v-chip v-if="((yeardata[i] || {}).stage === 0)" label small color="info">Open</v-chip>
                      <v-chip v-else-if="((yeardata[i] || {}).stage === 1)" label small color="warning">Pending review</v-chip>
                      <v-chip v-else-if="((yeardata[i] || {}).stage === 2)" label small color="success">Completed</v-chip>
                      <v-chip v-else-if="(((yeardata[i] || {}).stage || 0) === 0)" label small color="">Yet to start</v-chip>
                    </div>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text> -->


    </v-card>
  </div>
</template>

<script>
import DataTable from "../../../views/common/DataTable.vue";
import MonthlySummary from "../views/MonthlySummary.vue";
export default {
  name: 'tds_monthly',
  data: function(){
    return {
      loading: false,
      yearlist: [],
      yeardata: {},
      selectedyear: "",
      listMonthNew:[
        { 
          name:'April',
          indexno:4,
        },
        { 
          name:'May',
          indexno:5,
        },
        { 
          name:'June',
          indexno:6,
        },
        { 
          name:'July',
          indexno:7,
        },
        { 
          name:'August',
          indexno:8,
        },
        { 
          name:'September',
          indexno:9,
        },
        { 
          name:'October',
          indexno:10,
        },
        { 
          name:'November',
          indexno:11,
        },
        { 
          name:'December',
          indexno:12,
        },
        { 
          name:'January',
          indexno:1,
        },
        { 
          name:'February',
          indexno:2,
        },
        { 
          name:'March',
          indexno:3,
        },


        ],
      //listMonth:['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','January', 'February', 'March',],
      headerMonth: [
        {
          value: "month",
          text: "Month",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
        {
          value: "dashboard",
          text: "Compliance Dashboard ",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
         {
          value: "tds_monthly",
          text: "TDS Monthly ",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
         {
          value: "tds_quarterly",
          text: "TDS Quarterly  ",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
        {
          value: "gstr_1",
          text: "GSTR 1",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
        {
          value: "gstr_3b",
          text: "GSTR 3B",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
        {
          value: "refund",
          text: "Refund",
          sortable: false,
          filterable: false,
          alignment: "text-center",
        },
     ],
     formatedData:[],
    }
  },
  components: {
    DataTable, MonthlySummary
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  mounted() {
  },
  methods: {
     formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
       this.listMonthNew.forEach((item) => {
        ObjectTemp = {
          month:item.name,
          no:item.indexno,
          // __click:`/tds/monthly/${this.selectedyear}/${item.indexno}`
        }
       ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
     },
    refreshData() {
      this.yearlist = [];
      this.pushCurrentYear();
      if(this.$route.hash) this.selectedyear = this.$route.hash.replace("#", "");
      console.log("from hash", this.selectedyear)
      if(this.yearlist.indexOf(this.selectedyear) === -1) this.selectedyear = this.yearlist[this.yearlist.length-1] || "";
      console.log(this.yearlist);
      this.getData();
      this.formateData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/tds/monthly/getlist", {filter: {year: this.selectedyear}}).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.yearlist = [];
          for (const k of ((dt.data.data[0] || {}).yearlist || [])) {
             this.yearlist.push(k._id);
          }
          this.pushCurrentYear();
          if(!this.selectedyear) this.selectedyear = this.yearlist[this.yearlist.length-1];
          this.yeardata = {};

          for (const j of ((dt.data.data[0] || {}).yeardata || [])) {
             this.yeardata[j.month] = j;
          }
          for (const k in this.yeardata) {
            if (Object.hasOwnProperty.call(this.yeardata, k)) {
              const el = this.yeardata[k];
              let prevmonth = el.month -1;
              if(prevmonth === 0) prevmonth = 12;
              if((this.yeardata[prevmonth] || {}).stage === 3) el.__canstart = true;
              else el.__canstart = false;
            }
          }
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    pushCurrentYear() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth();
      if(month < 3) year = year-1;
      year = year + "-" + (year + 1).toString().split("").splice(2,2).join("");
      if(this.yearlist.indexOf(year) === -1) this.yearlist.push(year);
    },
    yearSelect(item){
      console.log(item,"SelectYear");
      this.selectedyear = item;
      this.getData();
    },
    SelectStage(stage,header,item){
      console.log(stage,header,item,this.selectedyear);
    },
  },
  computed: {
  },
  watch: {
    "selectedyear": function(d){
      if(!this.loading) {
        this.$nova.gotoLink({hash: d})
        this.getData();
      }
    }
  }
}
</script>
